.event {
  &__image {

    @include breakpoint(medium) {
      margin-left: 2 * $size;
    }
  }

  &__back {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    padding: 0.5rem;
  }

  &__date {
    font-size: 1.5rem;
    @include breakpoint(medium) {
      font-size: 2rem;
    }
  }

  &__buttons {
    padding: (2 * $size) 0;
  }
}
