.countdown {
  line-height: 1.5em;
  letter-spacing: 0.3em;
  margin: 2em 0;
  display: inline-block;
  font-size: 0.7rem;
  @include breakpoint(medium) {
    font-size: 1rem;
  }

  &__value {
    display: inline-block;
    border-right: 1px solid white;
    padding: 0 1em;
    text-align: center;

    &:last-of-type {
      border: none;
      padding-right: 0;
    }

    &:first-of-type {
      padding-left: 0;
    }
  }

  &__unit {
    font-size: 0.7em;
    text-transform: uppercase;
  }

  &__number {
    color: map-get($foundation-palette, primary);
    line-height: 1em;
    font-family: monospace;
    font-size: 4em;
  }
}
