.events {
  max-width: 1000px;
  margin: 0 auto;

  &__item {
    width: 270px!important;
    background-color: darken(white, 10%);
    transition: background-color 0.25s;
    &:hover {
      background-color: white;
    }
  }

  &__content {
    color: #0a0a0a;
    height: 100%;
  }

  &__button {
    margin-bottom: 0;
  }

  &__link {
    color: white;
    transition: color 0.5s;

    &:hover {
      color: map-get($foundation-palette, primary);
    }
  }

  &__date {
    margin: -0.3rem 0;
  }

  &__title {
    font-size: 1.3rem;
    line-height: 1em;
    padding-top: 1rem;
  }

  &__image {
    margin: 10px;
    width: 250px;
    height: 250px;
  }

  &__tags {
    width: 100%;
  }

}
