$size: 1rem;

$foundation-palette: (
  primary: #ff6d22,
  secondary: #767676,
  success: #1779ba,
  warning: #ffae00,
  alert: #cc4b37,
  dark: #000,
);

$menu-item-color-active: map-get($foundation-palette, primary);
$offcanvas-background: #ffffff;
$offcanvas-inner-shadow-color: white;
$offcanvas-inner-shadow-size: 3px;
$grid-container-padding: (
  medium: 5 * $size,
  small: 2 * $size,
);
$offcanvas-exit-background: transparent;
$button-hollow-hover-lightness: 100%;
