.home {
  display: flex;

  &__social {
    position: absolute;
    top: $size;
    right: $size;
  }

  &__social-icon {
    color: white;
    font-size: 2rem;
    transition: color 0.5s;

    &:hover {
      color: map-get($foundation-palette, primary);
    }
  }

  &__logo {
    width: 250px;
  }

  &__title {
    font-family: 'Coda', cursive;
    font-weight: normal;
    line-height: 1.2em;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin: 0;
  }
}
