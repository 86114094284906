.ccmc-menu {
  position: sticky;
  z-index: 1;
  top:0;
  height: $ccmc-menu-height;
  margin-top: -$ccmc-menu-height;
  padding: 0.5rem 0;
  background-color: rgba(0,0,0,0.5);

  &__item {
    transition: color 0.5s;
    color: white;
    text-transform: uppercase;

    &:focus {
      color: white;
    }

    &:hover {
      color: map-get($foundation-palette, primary);
    }

    &--active {
      &:focus {
        color: map-get($foundation-palette, primary);
      }
      color: map-get($foundation-palette, primary);
    }
  }

  &__button {
      position: absolute;
      top: 1.5 * $size;
      left: $size;
      z-index: 2;
  }
  .has-submenu-toggle > a {
    font-weight: bold;
  }
}
