@import 'foundation-settings';
@import '~foundation-sites/scss/foundation.scss';
@import '~leaflet/dist/leaflet.css';
@import url('https://fonts.googleapis.com/css?family=Coda');

@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-everything($prototype: true);

$ccmc-menu-height: 3rem;

@import 'components/breadcrumbs';
@import 'components/contact';
@import 'components/content';
@import 'components/countdown';
@import 'components/event';
@import 'components/events';
@import 'components/home';
@import 'components/loader';
@import 'components/map';
@import 'components/menu';
@import 'components/onamission-map';
@import 'components/page';
@import 'components/sponsors';
@import 'components/tags';

::selection {
  background: map-get($foundation-palette, primary);
}
:focus{
  outline: none !important;
}
html {
  scroll-behavior: smooth;
  margin-top: 0!important;
}
body {
  color: white;
  overflow-x: hidden;
  background-color: black;
}

img {
  max-width: 100%;
}

label {
  color: white;
}

ul {
  margin: 0;
  list-style-type: none;
}

iframe {
  border: none;
}

h1 {
  margin-bottom: 0;
}

.button.hollow {
  color: white;
}

.button.dark:hover, .button.dark:focus {
  background: map-get($foundation-palette, primary);
}

.ccmc-svg {
  height: 2rem;
  display: inline-block;
  width: 2rem;
  margin-right: 0.5rem;
}

.mtb-park {
  margin-bottom: 2rem;
  position: relative;
  padding-top: 66%;
  width: 100%;

  &__map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

table {
  background-color: white;
  color: black;
}