.loader {
  animation-duration: 2s;
  animation-name: pulse;
  animation-iteration-count: infinite;
  font-size: 2rem;
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &__image {
    width: 150px;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}
