.ccmc-breadcrumbs {
  &__current {
    color: map-get($foundation-palette, primary)!important; // Overriding foudnation.
  }

  &__parent {
    color: white!important; // Overriding foudnation.
  }

}
