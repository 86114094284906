.sponsors {
    &__sponsor {
      padding: 3em;
      position: relative;
    }

    &__image {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      margin: 0 auto;
      padding-top: 40%;
      width: 80%;
    }

    &__arrow {
      cursor: pointer;
      position: absolute;
      top: 50%;
      margin-top: -.5em;
      z-index: 1;

      &--previous {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }
}
