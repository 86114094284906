.content {

  min-height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-bottom: $ccmc-menu-height;
  padding-top: $ccmc-menu-height;

  &__title {
    font-size: 3rem;
    line-height: 1em;
    font-family: 'Coda', cursive;

    @include breakpoint(small only) {
      padding-left: 2rem;
      font-size: 2rem;
    }
  }

  &__background {
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0.4;
  }

  &__grid-container {
    padding-top: $size;
    padding-bottom: $size;

    @include breakpoint(medium) {
      padding-top: 2.5 * $size;
      padding-bottom: 2.5 * $size;
    }
  }

}
